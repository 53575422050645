import React, { Component } from "react";
import { Navbar,Nav,NavDropdown,Form,FormControl } from 'react-bootstrap';
import { Button, Input } from "antd";

class Header extends Component {

	state = {

	}
	componentDidMount(){
		var token = localStorage.getItem('token')
		if(!token){
			window.location.assign('/')
		}
	}
	logout = () => {
		localStorage.clear()
		window.location.assign('/')
	}
	render() {
		return (
			<section className="__header_section">

				<Navbar expand="lg">
				  <Navbar.Brand href="#home"><img src="./assets/logo.png" style={{ height: 37 }} alt="logo" /></Navbar.Brand>
				  <Navbar.Toggle aria-controls="basic-navbar-nav" />
				  <Navbar.Collapse id="basic-navbar-nav" >
			    	<Nav className="m-auto" style={{ opacity:0 }}>
			    		<Nav.Link >Home</Nav.Link>
			    	</Nav>
				    <Form inline>
				      <Button type="button" className="logout-btn" onClick={this.logout}>Logout</Button>
				    </Form>
				  </Navbar.Collapse>
				</Navbar>
				{/*<div className="container-fluid">
					<div className="row">
						<div className="col-lg-2">
							<img src="./assets/logo.png" alt="logo" />
						</div>
						<div className="col-lg-10 header_menu">
							<div className="setting-icon">
								<button type="button" onClick={this.logout}>Logout</button>
							</div>
						</div>
					</div>
				</div>*/}
			</section>
		)
	}

}

export default Header;
















