import React, { Component } from "react";
import "./App.css";
import "antd/dist/antd.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Nav } from "react-bootstrap";
import Login from "./screens/Login";
import ProductPlan from "./screens/ProductPlan";
import FrameDetails from "./screens/FrameDetails";
import Home from "./screens/Home";
import { createBrowserHistory } from "history";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  componentDidMount() {
    
  }

  render() {

    return (
      <Router>
        <Switch>
          <Route exact strict path="/" component={Login} />
          <Route exact strict path="/Home" component={Home} />
          <Route exact strict path="/ProductPlan" component={ProductPlan} />
          <Route exact strict path="/FrameDetails" component={FrameDetails} />
        </Switch>
      </Router>
    );
  }
}

export default App;
