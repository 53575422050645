import React, { Component } from "react";
import Header from './Header';
import { Tabs, Radio, Table,Tag,Space, message, Image } from 'antd';
import moment from "moment";
import axios from "axios";
import XLSX from "xlsx";
import STRINGS from '../consts';
import Modal from "antd/lib/modal/Modal";

const { TabPane } = Tabs;

class ProductPlan extends Component {

	state = {
		tabPosition: 'top',
		currentDate: "Today: "+moment().format('DD/MM/YYYY'),
		tommorrow: "Tomorrow: "+moment().add(1, 'days').format('DD/MM/YYYY'),
		todayDate: moment().format('DD/MM/YYYY'),
		tomorrowDate: moment().add(1, 'days').format('DD/MM/YYYY'),
		productPlanData:[{srNo:1,shift:1}],
		productPlanData2:[{srNo:1,shift:2}],
		productPlanDataT:[{srNo:1,shift:1}],
		productPlanDataT2:[{srNo:1,shift:2}],
		permissions:""
	}

	componentDidMount(){
		this.getProductionPlan(this.state.todayDate,"productPlanData","productPlanData2")
		this.getProductionPlan(this.state.tomorrowDate,"productPlanDataT","productPlanDataT2")
		this.getLineSpeed(this.state.todayDate)
		this.getLineSpeed(this.state.tomorrowDate)
		var permissions = localStorage.getItem('permissions')
		this.setState({permissions})
		setInterval(()=>{
			this.getProductionPlan(this.state.todayDate,"productPlanData","productPlanData2")
			this.getProductionPlan(this.state.tomorrowDate,"productPlanDataT","productPlanDataT2")
		},300000)
	}

	exportToExcel = (array1,array2,date) => {
		
			let shift1 = array1.map((item) => {
			  return [
				item.shift,
				item.srNo,
				item.name,
				item.workOrderNo,
				item.sku,
				item.color,
				item.variant,
				item.qty
			  ];
			});
			let shift2 = array2.map((item) => {
				return [
				  item.shift,
				  item.srNo,
				  item.name,
				  item.workOrderNo,
				  item.sku,
				  item.color,
				  item.variant,
				  item.qty
				];
			  });
	
			const defaultHeaders = [
			  "Shift",
			  "Seq No",
			  "Model",
			  "W/O",
			  "SKU",
			  "COLOUR",
			  "DESCRIPTION",
			  "QTY"
			];
	
			const headers = defaultHeaders;
	
			const fileName = `SKU WISE PLAN DATE ${date}.xlsx`;
	
			const data = [headers];
			const xlsxData = data.concat(shift1).concat(shift2);
			const ws = XLSX.utils.aoa_to_sheet(xlsxData);
			ws["!cols"] = [];
			ws["!rows"] = [];
			ws["!cols"][6] = { wch: 35 };
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "Production Plan");
			XLSX.writeFile(wb, fileName);
	}
	getProductionPlan = (date,array1,array2) => {
		axios.get(process.env.REACT_APP_BASE_URL+"/get/getProductionPlanByDate?date="+date).then((res)=>{
			console.log(res)
			if(res.data.status===1){
				let dataArray = res.data.data
				console.log(dataArray)
				let shift1 = []
				let shift2 = []
				for(let i=0;i<dataArray.length;i++){
					if(dataArray[i].shift===1){
						dataArray[i].variant = res.data.data[i].variant+" "+res.data.data[i].model
						shift1 = [...shift1,dataArray[i]]
					}else{
						dataArray[i].variant = res.data.data[i].variant+" "+res.data.data[i].model
						shift2 = [...shift2,dataArray[i]]
					}
				}
				this.setState({[array1]:shift1,[array2]:shift2},()=>console.log(this.state[array1]))
			}else{
				this.setState({[array1]:[],[array2]:[]})
				message.error(res.data.message)
			}
		})
	}
	getLineSpeed = (date) => {
		axios.get(process.env.REACT_APP_BASE_URL+"/get/getLineSpeedByDate?date="+date).then((res)=>{
			console.log(res)
			if(res.data.status===1){
				let dataArray = res.data.data
				for(let i=0;i<dataArray.length;i++){
					if(dataArray[i].shift==="1"){
						if(date===this.state.todayDate){
							this.setState({
								shift1LineSpeed:dataArray[i].line_speed,
								shift1planHour:dataArray[i].plan_hour
							})
						}else{
							this.setState({
								shiftT1LineSpeed:dataArray[i].line_speed,
								shiftT1planHour:dataArray[i].plan_hour
							})
						}
					}else{
						if(date===this.state.todayDate){
							this.setState({
								shift2LineSpeed:dataArray[i].line_speed,
								shift2planHour:dataArray[i].plan_hour
							})
						}else{
							this.setState({
								shiftT2LineSpeed:dataArray[i].line_speed,
								shiftT2planHour:dataArray[i].plan_hour
							})
						}
					}
				}
			}else{
				
			}
		})
	}
	handleMouseIn = (text) => {
		this.setState({hover:true,image:text+".jpeg"})
	}
	handleMouseOut = (text) => {
		this.setState({hover:false})
	}
	render() {
		// PRODUCTION PLAN
		const productPlanColumns = [
		  {
			title: 'Shift',
			dataIndex: 'shift',
			key: 'shift',
			width: 70
		  },
		  {
			title: 'Seq No',
			dataIndex: 'srNo',
			key: 'srNo',
			render: (text,record,index) => (
			   <div>
				   {(record.originalSrNo>record.srNo) ? <div><i class='bx bx-upvote action_btns_green'>{Number(record.originalSrNo)-Number(record.srNo)}</i><p>{record.srNo}</p></div> : null}
				   {(record.originalSrNo<record.srNo) ? <div><i class='bx bx-downvote action_btns_delete'>{Number(record.srNo)-Number(record.originalSrNo)}</i><p>{record.srNo}</p></div> : null}
				   {(record.originalSrNo==record.srNo || record.originalSrNo===undefined) ? <div>{record.srNo}</div> : null}
			   </div>
			)
		  },
		  {
		    title: 'Model',
		    dataIndex: 'name',
		    key: 'name',
		    width: 320
		  },
		  {
		    title: 'Work Order No',
		    dataIndex: 'workOrderNo',
		    key: 'workOrderNo'
		  },
		  {
		    title: 'SKU',
		    dataIndex: 'sku',
		    key: 'sku'
		  },
		  {
		    title: 'Color',
		    dataIndex: 'color',
		    key: 'color',
		    width: 320
		  },
		  {
		    title: 'Description',
		    dataIndex: 'variant',
		    key: 'variant',
		    width: 320
		  },
		  {
		    title: 'Qty',
		    dataIndex: 'qty',
			key: 'qty',
		    width: 320,
		    
		  }
		];
		const productPlanColumns2 = [
			{
			  title: 'Shift',
			  dataIndex: 'shift',
			  key: 'shift',
			  width: 70
			},
			{
				title: 'Seq No',
				dataIndex: 'srNo',
				key: 'srNo',
				render: (text,record,index) => (
				   <div>
					   {(record.originalSrNo>record.srNo) ? <div><i class='bx bx-upvote action_btns_green'>{Number(record.originalSrNo)-Number(record.srNo)}</i><p>{record.srNo}</p></div> : null}
					   {(record.originalSrNo<record.srNo) ? <div><i class='bx bx-downvote action_btns_delete'>{Number(record.srNo)-Number(record.originalSrNo)}</i><p>{record.srNo}</p></div> : null}
					   {(record.originalSrNo==record.srNo || record.originalSrNo===undefined) ? <div>{record.srNo}</div> : null}
				   </div>
				)
			},
			{
			  title: 'Model',
			  dataIndex: 'name',
			  key: 'name',
			  width: 320
			},
			{
				title: 'Work Order No',
				dataIndex: 'workOrderNo',
				key: 'workOrderNo',
			},
			{
			  title: 'SKU',
			  dataIndex: 'sku',
			  key: 'sku',
			 
			},
			{
			  title: 'Color',
			  dataIndex: 'color',
			  key: 'color',
			  width: 320
			},
			{
			  title: 'Description',
			  dataIndex: 'variant',
			  key: 'variant',
			  width: 320
			},
			{
			title: 'Qty',
			key: 'qty',
		    dataIndex: 'qty',
			width: 320,
			}
		];
		const productPlanColumnsT = [
			{
			  title: 'Shift',
			  dataIndex: 'shift',
			  key: 'shift',
			  width: 70
			},
			{
				title: 'Seq No',
				dataIndex: 'srNo',
				key: 'srNo',
				render: (text,record,index) => (
				   <div>
					   {(record.originalSrNo>record.srNo) ? <div><i class='bx bx-upvote action_btns_green'>{Number(record.originalSrNo)-Number(record.srNo)}</i><p>{record.srNo}</p></div> : null}
					   {(record.originalSrNo<record.srNo) ? <div><i class='bx bx-downvote action_btns_delete'>{Number(record.srNo)-Number(record.originalSrNo)}</i><p>{record.srNo}</p></div> : null}
					   {(record.originalSrNo==record.srNo || record.originalSrNo===undefined) ? <div>{record.srNo}</div> : null}
				   </div>
				)
			},
			{
			  title: 'Model',
			  dataIndex: 'name',
			  key: 'name',
			  width: 320
			},
			{
				title: 'Work Order No',
				dataIndex: 'workOrderNo',
				key: 'workOrderNo'
			},
			{
			  title: 'SKU',
			  dataIndex: 'sku',
			  key: 'sku'
			},
			{
			  title: 'Color',
			  dataIndex: 'color',
			  key: 'color',
			  width: 320
			},
			{
			  title: 'Description',
			  dataIndex: 'variant',
			  key: 'variant',
			  width: 320
			},
			{
				title: 'Qty',
				key: 'qty',
				dataIndex: 'qty',
				width: 320
			}
		];
		const productPlanColumnsT2 = [
			{
			  title: 'Shift',
			  dataIndex: 'shift',
			  key: 'shift',
			  width: 70
			},
			{
				title: 'Seq No',
				dataIndex: 'srNo',
				key: 'srNo',
				render: (text,record,index) => (
				   <div>
					   {(record.originalSrNo>record.srNo) ? <div><i class='bx bx-upvote action_btns_green'>{Number(record.originalSrNo)-Number(record.srNo)}</i><p>{record.srNo}</p></div> : null}
					   {(record.originalSrNo<record.srNo) ? <div><i class='bx bx-downvote action_btns_delete'>{Number(record.srNo)-Number(record.originalSrNo)}</i><p>{record.srNo}</p></div> : null}
					   {(record.originalSrNo==record.srNo || record.originalSrNo===undefined) ? <div>{record.srNo}</div> : null}
				   </div>
				)
			},
			{
			  title: 'Model',
			  dataIndex: 'name',
			  key: 'name',
			  width: 320
			},
			{
				title: 'Work Order No',
				dataIndex: 'workOrderNo',
				key: 'workOrderNo'
			},
			{
			  title: 'SKU',
			  dataIndex: 'sku',
			  key: 'sku'
			},
			{
			  title: 'Color',
			  dataIndex: 'color',
			  key: 'color',
			  width: 320
			},
			{
			  title: 'Description',
			  dataIndex: 'variant',
			  key: 'variant',
			  width: 320
			},
			{
				title: 'Qty',
				key: 'qty',
				dataIndex: 'qty',
				width: 320
			}
		];
		// FRAME DETAILS
		const frameDetailsColumns = [
			{
				title: 'Shift',
				dataIndex: 'shift',
				key: 'shift',
				width: 70,
				fixed: 'left'
			},
			{
				title: 'Seq No',
				dataIndex: 'srNo',
				key: 'srNo',
				fixed: 'left',
				width:100,
				render: (text,record,index) => (
				   <div>
					   {(record.originalSrNo>record.srNo) ? <div><i class='bx bx-upvote action_btns_green'>{Number(record.originalSrNo)-Number(record.srNo)}</i><p>{record.srNo}</p></div> : null}
					   {(record.originalSrNo<record.srNo) ? <div><i class='bx bx-downvote action_btns_delete'>{Number(record.srNo)-Number(record.originalSrNo)}</i><p>{record.srNo}</p></div> : null}
					   {(record.originalSrNo==record.srNo || record.originalSrNo===undefined) ? <div>{record.srNo}</div> : null}
				   </div>
				)
			},
		  {
		    title: 'SKU',
		    dataIndex: 'sku',
		    key: 'sku',
			fixed: 'left',
			width: 100
		  },
		  {
		    title: 'Frame Sub Assly',
		    dataIndex: 'frame_assembly',
		    key: 'frame_assembly',
			width: 100
		  },
		  {
		    title: 'Painted Frame',
		    dataIndex: 'painted_frame',
		    key: 'painted_frame',
			width: 100
		  },
		  {
		    title: 'Single Frame',
		    dataIndex: 'single_frame',
		    key: 'single_frame',
			width: 100
		  },
		  {
		    title: 'Front Frame',
		    dataIndex: 'front_frame',
		    key: 'front_frame',
			width: 130,
			render:(text,record,index)=>(
				<div style={{flexDirection:'row',display:'flex'}}>
					<p>{text}</p>
					<div onClick={()=>this.handleMouseIn(text)}><i style={{fontSize:18,marginLeft:10}} class='bx bxs-info-circle'></i></div>
				</div>
			)
		  },
		  {
		    title: 'Front Frame Color',
		    dataIndex: 'ff_color',
		    key: 'ff_color',
			width: 100
		  },
		  {
		    title: 'Rear Frame',
		    dataIndex: 'rear_frame',
		    key: 'rear_frame',
			width: 130,
			render:(text,record,index)=>(
				<div style={{flexDirection:'row',display:'flex'}}>
					<p>{text}</p>
					<div onClick={()=>this.handleMouseIn(text)}><i style={{fontSize:18,marginLeft:10}} class='bx bxs-info-circle'></i></div>
				</div>
			)
		  },
		  {
		    title: 'Rear Frame Color',
		    dataIndex: 'rf_color',
		    key: 'rf_color',
			width: 100
		  },
		  {
		    title: 'ABS Kit',
		    dataIndex: 'abs_kit_dbs_system',
		    key: 'abs_kit_dbs_system',
			width: 130,
			render:(text,record,index)=>(
				<div style={{flexDirection:'row',display:'flex'}}>
					<p>{text}</p>
					<div onClick={()=>this.handleMouseIn(text)}><i style={{fontSize:18,marginLeft:10}} class='bx bxs-info-circle'></i></div>
				</div>
			)
		  },
		  {
		    title: 'Silencer Sub Assly',
		    dataIndex: 'silencer_sub',
		    key: 'silencer_sub',
			width: 130,
			render:(text,record,index)=>(
				<div style={{flexDirection:'row',display:'flex'}}>
					<p>{text}</p>
					<div onClick={()=>this.handleMouseIn(text)}><i style={{fontSize:18,marginLeft:10}} class='bx bxs-info-circle'></i></div>
				</div>
			)
		  },
		  {
		    title: 'Harness Sub Assly',
		    dataIndex: 'harness_assembly',
		    key: 'harness_assembly',
			width: 130,
			render:(text,record,index)=>(
				<div style={{flexDirection:'row',display:'flex'}}>
					<p>{text}</p>
					<div onClick={()=>this.handleMouseIn(text)}><i style={{fontSize:18,marginLeft:10}} class='bx bxs-info-circle'></i></div>
				</div>
			)
		  },
		//   {
		//     title: 'Step Holder Rider LH',
		//     dataIndex: 'step_holder_rider_lh',
		//     key: 'step_holder_rider_lh',
		// 	width: 100
		//   },
		  {
		    title: 'Step Holder Sub Assly LH',
		    dataIndex: 'holder_assembly_lh',
		    key: 'holder_assembly_lh',
			width: 100
		  },
		  {
		    title: 'Step Holder Sub Assly RH',
		    dataIndex: 'holder_assembly_rh',
		    key: 'holder_assembly_rh',
			width: 100
		  },
		//   {
		//     title: 'Step Holder Rider RH',
		//     dataIndex: 'step_holder_rider_rh',
		//     key: 'step_holder_rider_rh',
		// 	width: 100
		//   },
		  {
		    title: 'Monoshock',
		    dataIndex: 'shock_absorber_rear',
		    key: 'shock_absorber_rear',
			width: 130,
			render:(text,record,index)=>(
				<div style={{flexDirection:'row',display:'flex'}}>
					<p>{text}</p>
					<div onClick={()=>this.handleMouseIn(text)}><i style={{fontSize:18,marginLeft:10}} class='bx bxs-info-circle'></i></div>
				</div>
			)
		  },
		];

		//WHEEL DETAILS
		const wheelDetailsColumns = [
			{
				title: 'Shift',
				dataIndex: 'shift',
				key: 'shift',
				width: 70,
				fixed:'left'
			},
			{
				title: 'Seq No',
				dataIndex: 'srNo',
				key: 'srNo',
				width:100,
				fixed:'left',
				render: (text,record,index) => (
				   <div>
					   {(record.originalSrNo>record.srNo) ? <div><i class='bx bx-upvote action_btns_green'>{Number(record.originalSrNo)-Number(record.srNo)}</i><p>{record.srNo}</p></div> : null}
					   {(record.originalSrNo<record.srNo) ? <div><i class='bx bx-downvote action_btns_delete'>{Number(record.srNo)-Number(record.originalSrNo)}</i><p>{record.srNo}</p></div> : null}
					   {(record.originalSrNo==record.srNo || record.originalSrNo===undefined) ? <div>{record.srNo}</div> : null}
				   </div>
				)
			},
			  {
				title: 'SKU',
				dataIndex: 'sku',
				key: 'sku',
				width: 100,
				fixed:'left'
			  },
			  {
				title: 'Front Wheel Assly',
				dataIndex: 'front_wheel',
				key: 'front_wheel',
				width: 100
			  },
			  {
				title: 'Front Wheel Color',
				dataIndex: 'front_wheel_color',
				key: 'front_wheel_color',
				width: 100
			  },
			  {
				title: 'Front Wheel Tyre',
				dataIndex: 'fw_tyre',
				key: 'fw_tyre',
				width: 100
			  },
			  {
				title: 'Front Tyre Make',
				dataIndex: 'fw_tyre_company',
				key: 'fw_tyre_company',
				width: 100
			  },
			  {
				title: 'Front Disc',
				dataIndex: 'disc_brake',
				key: 'disc_brake',
				width: 100
			  },
			  {
				title: 'Front Wheel Decal LH',
				dataIndex: 'fw_decal_lh',
				key: 'fw_decal_lh',
				width: 100
			  },
			  {
				title: 'Front Wheel Decal RH',
				dataIndex: 'fw_decal_rh',
				key: 'fw_decal_rh',
				width: 100
			  },
			//   {
			// 	title: 'Front Wheel Decal 3D',
			// 	dataIndex: 'fw_decal_3d',
			// 	key: 'fw_decal_3d',
			// 	width: 100
			//   },
			  {
				title: 'Rear Wheel Assly',
				dataIndex: 'rear_wheel',
				key: 'rear_wheel',
				width: 100
			  },
			  {
				title: 'Rear Wheel Color',
				dataIndex: 'rear_wheel_color',
				key: 'rear_wheel_color',
				width: 100
			  },
			  {
				title: 'Rear Disc',
				dataIndex: 'damper',
				key: 'damper',
				width: 100
			  },
			  {
				title: 'Rear Wheel Tyre',
				dataIndex: 'tyre',
				key: 'tyre',
				width: 100
			  },
			  {
				title: 'Rear Tyre Make',
				dataIndex: 'tyre_company',
				key: 'tyre_company',
				width: 100
			  },
			  {
				title: 'Rear Wheel Decal LH',
				dataIndex: 'decal_lh',
				key: 'decal_lh',
				width: 100
			  },
			  {
				title: 'Rear Wheel Decal RH',
				dataIndex: 'decal_rh',
				key: 'decal_rh',
				width: 100
			  },
			//   {
			// 	title: 'Rear Wheel Decal 3D',
			// 	dataIndex: 'decal_3d',
			// 	key: 'decal_3d',
			// 	width: 100
			//   }
		]

		const frFairingColumns = [
			{
				title: 'Shift',
				dataIndex: 'shift',
				key: 'shift',
				width: 70,
				fixed:'left'
			},
			{
				title: 'Seq No',
				dataIndex: 'srNo',
				key: 'srNo',
				width:100,
				fixed:'left',
				render: (text,record,index) => (
				   <div>
					   {(record.originalSrNo>record.srNo) ? <div><i class='bx bx-upvote action_btns_green'>{Number(record.originalSrNo)-Number(record.srNo)}</i><p>{record.srNo}</p></div> : null}
					   {(record.originalSrNo<record.srNo) ? <div><i class='bx bx-downvote action_btns_delete'>{Number(record.srNo)-Number(record.originalSrNo)}</i><p>{record.srNo}</p></div> : null}
					   {(record.originalSrNo==record.srNo || record.originalSrNo===undefined) ? <div>{record.srNo}</div> : null}
				   </div>
				)
			   },
			  {
				title: 'SKU',
				dataIndex: 'sku',
				key: 'sku',
				width:100,
				fixed:'left'
			  },
			  {
				title: 'Fairing Sub Assly',
				dataIndex: 'front_fairing',
				key: 'front_fairing',
				width:100
			  },
			  {
				title: 'Lamp Head',
				dataIndex: 'lamp_head',
				key: 'lamp_head',
				width:100
			  },
			  {
				title: 'Indicator Front LH',
				dataIndex: 'indicator_front_lh',
				key: 'indicator_front_lh',
				width:100
			  },
			  {
				title: 'Indicator Front RH',
				dataIndex: 'indicator_front_rh',
				key: 'indicator_front_rh',
				width:100
			  },
			  {
				title: 'Side Mask LH',
				dataIndex: 'side_mask_lh',
				key: 'side_mask_lh',
				width:100
			  },
			  {
				title: 'Side Mask RH',
				dataIndex: 'side_mask_rh',
				key: 'side_mask_rh',
				width:100
			  },
		]
		const decalColumns = [
			{
				title: 'Shift',
				dataIndex: 'shift',
				key: 'shift',
				width: 70
			},
			{
				title: 'Seq No',
				dataIndex: 'srNo',
				key: 'srNo',
				render: (text,record,index) => (
				   <div>
					   {(record.originalSrNo>record.srNo) ? <div><i class='bx bx-upvote action_btns_green'>{Number(record.originalSrNo)-Number(record.srNo)}</i><p>{record.srNo}</p></div> : null}
					   {(record.originalSrNo<record.srNo) ? <div><i class='bx bx-downvote action_btns_delete'>{Number(record.srNo)-Number(record.originalSrNo)}</i><p>{record.srNo}</p></div> : null}
					   {(record.originalSrNo==record.srNo || record.originalSrNo===undefined) ? <div>{record.srNo}</div> : null}
				   </div>
				)
			   },
			  {
				title: 'SKU',
				dataIndex: 'sku',
				key: 'sku',
				width:320
			  },
			  {
				title: 'Decal Scheme',
				dataIndex: 'decal_scheme',
				key: 'decal_scheme',
				width:320
			  },
			  {
				  title: 'Color Scheme',
				  dataIndex: 'color_scheme',
				  key: 'color_scheme',
				  width:320
			  }
		]
		const paintedPartsColumns = [
			{
				title: 'Shift',
				dataIndex: 'shift',
				key: 'shift',
				width: 70,
				fixed:'left'
			},
			{
				title: 'Seq No',
				dataIndex: 'srNo',
				key: 'srNo',
				width:100,
				fixed:'left',
				render: (text,record,index) => (
				   <div>
					   {(record.originalSrNo>record.srNo) ? <div><i class='bx bx-upvote action_btns_green'>{Number(record.originalSrNo)-Number(record.srNo)}</i><p>{record.srNo}</p></div> : null}
					   {(record.originalSrNo<record.srNo) ? <div><i class='bx bx-downvote action_btns_delete'>{Number(record.srNo)-Number(record.originalSrNo)}</i><p>{record.srNo}</p></div> : null}
					   {(record.originalSrNo==record.srNo || record.originalSrNo===undefined) ? <div>{record.srNo}</div> : null}
				   </div>
				)
			},	
			{
				title: 'SKU',
				dataIndex: 'sku',
				key: 'sku',
				width:100,
				fixed:'left'
			},
			{
				title: 'Tank Assembly',
				dataIndex: 'tank_assembly',
				key: 'tank_assembly',
				width:100
			},
			{
				title: 'Tank Assembly Color',
				dataIndex: 'tank_assembly_color',
				key: 'tank_assembly_color',
				width:100
			},
			{
				title: 'Fuel Tank',
				dataIndex: 'fuel_tank',
				key: 'fuel_tank',
				width:100
			},
			{
				title: 'Fuel Tank Color',
				dataIndex: 'fuel_tank_color',
				key: 'fuel_tank_color',
				width:100
			},
			{
				title: 'Tank Cover',
				dataIndex: 'tank_cover',
				key: 'tank_cover',
				width:100
			},
			{
				title: 'Tank Cover Color Top',
				dataIndex: 'color_tank_cover_top',
				key: 'color_tank_cover_top',
				width:100
			},
			{
				title: 'Fuel Tank Cover',
				dataIndex: 'fuel_tank_cover',
				key: 'fuel_tank_cover',
				width:100
			},
			{
				title: 'Fuel Tank Cover Color LH',
				dataIndex: 'color_fuel_tank_cover_lh',
				key: 'color_fuel_tank_cover_lh',
				width:100
			},
			{
				title: 'Fuel Tank Cover RH',
				dataIndex: 'fuel_tank_cover_rh',
				key: 'fuel_tank_cover_rh',
				width:100
			},
			{
				title: 'Fuel Tank Cover Color RH',
				dataIndex: 'color_fuel_tank_cover_rh',
				key: 'color_fuel_tank_cover_rh',
				width:100
			},
			{
				title: 'Seat Cowl Upper Single',
				dataIndex: 'seat_cowl_upper_single',
				key: 'seat_cowl_upper_single',
				width:100
			},
			{
				title: 'Seat Cowl Upper Single Color',
				dataIndex: 'color_seat_cowl_upper_single',
				key: 'color_seat_cowl_upper_single',
				width:100
			},
			{
				title: 'Seat Cowl UL',
				dataIndex: 'seat_cowl_ul',
				key: 'seat_cowl_ul',
				width:100
			},
			{
				title: 'Seat Cowl UL Color',
				dataIndex: 'color_seat_cowl_ul',
				key: 'color_seat_cowl_ul',
				width:100
			},
			{
				title: 'Seat Cowl UR',
				dataIndex: 'seat_cowl_ur',
				key: 'seat_cowl_ur',
				width:100
			},
			{
				title: 'Seat Cowl UR Color',
				dataIndex: 'color_seat_cowl_ur',
				key: 'color_seat_cowl_ur',
				width:100
			},
			{
				title: 'Seat Cowl Lower LH',
				dataIndex: 'seat_cowl_lower_lh',
				key: 'seat_cowl_lower_lh',
				width:100
			},
			{
				title: 'Seat Cowl Lower LH Color',
				dataIndex: 'color_seat_cowl_lower_lh',
				key: 'color_seat_cowl_lower_lh',
				width:100
			},
			{
				title: 'Seat Cowl Lower RH',
				dataIndex: 'seat_cowl_lower_rh',
				key: 'seat_cowl_lower_rh',
				width:100
			},
			{
				title: 'Seat Cowl Lower RH Color',
				dataIndex: 'color_seat_cowl_lower_rh',
				key: 'color_seat_cowl_lower_rh',
				width:100
			},
			{
				title: 'Front Fender Front',
				dataIndex: 'front_fender_front',
				key: 'front_fender_front',
				width:100
			},
			{
				title: 'Front Fender Front Color',
				dataIndex: 'color_front_fender_front',
				key: 'color_front_fender_front',
				width:100
			},
			{
				title: 'Front Fender Rear',
				dataIndex: 'front_fender_rear',
				key: 'front_fender_rear',
				width:100
			},
			{
				title: 'Front Fender Rear Color',
				dataIndex: 'color_front_fender_rear',
				key: 'color_front_fender_rear',
				width:100
			},
			{
				title: 'Bellypan',
				dataIndex: 'bellypan',
				key: 'bellypan',
				width:100
			},
			{
				title: 'Bellypan Color',
				dataIndex: 'color_bellypan',
				key: 'color_bellypan',
				width:100
			},
			{
				title: 'Radiator Shroud',
				dataIndex: 'radiator_shroud',
				key: 'radiator_shroud',
				width:100
			},
			{
				title: 'Radiator Shroud Color',
				dataIndex: 'color_radiator_shroud',
				key: 'color_radiator_shroud',
				width:100
			},
			{
				title: 'Radiator Cover LH',
				dataIndex: 'radiator_cover_lh',
				key: 'radiator_cover_lh',
				width:100
			},
			{
				title: 'Radiator Cover LH Color',
				dataIndex: 'color_radiator_cover_lh',
				key: 'color_radiator_cover_lh',
				width:100
			},
			{
				title: 'Radiator Cover RH',
				dataIndex: 'radiator_cover_rh',
				key: 'radiator_cover_rh',
				width:100
			},
			{
				title: 'Radiator Cover RH Color',
				dataIndex: 'color_radiator_cover_rh',
				key: 'color_radiator_cover_rh',
				width:100
			},
			{
				title: 'Side Mask LH',
				dataIndex: 'side_mask_lh',
				key: 'side_mask_lh',
				width:100
			},
			{
				title: 'Side Mask LH Color',
				dataIndex: 'color_side_mask_lh',
				key: 'color_side_mask_lh',
				width:100
			},
			{
				title: 'Side Mask RH Color',
				dataIndex: 'side_mask_rh',
				key: 'side_mask_rh',
				width:100
			},
			{
				title: 'Side Mask RH Color',
				dataIndex: 'color_side_mask_rh',
				key: 'color_side_mask_rh',
				width:100
			},

		]
		const { tabPosition } = this.state;

		return (
			<section className="__productplan_section">
				<Header />
				<div className="container">
					<div className="row">
							<div className="__date_tabs">
								<Tabs onChange={this.callback} type="card">
								    <TabPane tab={this.state.currentDate} key="1">
								      
								    	<div className="row __internal_tabs">
								    		<div className="col-lg-12">
								    			<Tabs onChange={this.callback2} type="card" style={{width:'1100px'}}>
												    <TabPane tab="Production Plan" key="1">
												    	<br/>
												    		<div>
												    		<span style={{
												    			fontSize: 18,
															    paddingRight: 21,
															    fontWeight: 600
												    		}}>Shift 1</span>
<label style={{fontSize:16,marginLeft:150}}>Line Speed: </label>
															<input type="text" className="table_input" name="shift1LineSpeed" disabled={true} value={this.state.shift1LineSpeed} placeholder="Line Speed" style={{ width: 130, marginLeft:10 }} onChange={(e)=>this.handleTextChange(e)}/>
															<label style={{fontSize:16,marginLeft:10}}>Plan/Hour: </label>
															<input type="text" className="table_input" name="shift1planHour" disabled={true} value={this.state.shift1planHour} placeholder="Plan / Hour" style={{ width: 130,marginLeft:10 }} onChange={(e)=>this.handleTextChange(e)}/>
															<button style={{ marginBottom:10,borderRadius:58, float:'right' }} onClick={()=>this.exportToExcel(this.state.productPlanData,this.state.productPlanData2,this.state.currentDate)} className="add_btn save-btn">EXPORT TO EXCEL</button>
															</div>
												    	<br/>
												      <Table bordered columns={productPlanColumns} dataSource={this.state.productPlanData} />
													  <br/>
												    	
												    		<span style={{
												    			fontSize: 18,
															    paddingRight: 21,
															    fontWeight: 600
												    		}}>Shift 2</span>
															<label style={{fontSize:16,marginLeft:150}}>Line Speed: </label>
															<input type="text" className="table_input" name="shift2LineSpeed" disabled={true} value={this.state.shift2LineSpeed} placeholder="Line Speed" style={{ width: 130, marginLeft:10 }} onChange={(e)=>this.handleTextChange(e)}/>
															<label style={{fontSize:16,marginLeft:10}}>Plan/Hour: </label>
															<input type="text" className="table_input" name="shift2planHour" disabled={true} value={this.state.shift2planHour} placeholder="Plan / Hour" style={{ width: 130,marginLeft:10 }} onChange={(e)=>this.handleTextChange(e)}/>
												    	<br/>
												      <Table bordered columns={productPlanColumns2} dataSource={this.state.productPlanData2} />
												    </TabPane>
													{(this.state.permissions.includes('Frame Details')) ? <TabPane tab="Frame Details" key="2">
													<div style={{marginTop:50,marginBottom:50}}>
													<br/>
												    	
												    		<span style={{
												    			fontSize: 18,
															    paddingRight: 21,
															    fontWeight: 600
												    		}}>Shift 1</span>
												    	<br/>
												      <Table scroll={{ x:1300 }} bordered columns={frameDetailsColumns} dataSource={this.state.productPlanData} />
													  </div>
													  <br/>
													  <div>
												    		<span style={{
												    			fontSize: 18,
															    paddingRight: 21,
															    fontWeight: 600
												    		}}>Shift 2</span>
												    	<br/>
														<Table scroll={{ x:1300 }} bordered columns={frameDetailsColumns} dataSource={this.state.productPlanData2} />
														</div>
												    </TabPane>
												    :null }
													{(this.state.permissions.includes('Wheel Details')) ? <TabPane tab="Wheel Details" key="3">
													<div style={{marginTop:50,marginBottom:50}}>
													<br/>
												    	
												    		<span style={{
												    			fontSize: 18,
															    paddingRight: 21,
															    fontWeight: 600
												    		}}>Shift 1</span>
												    	<br/>
													  <Table scroll={{ x:1300 }} bordered columns={wheelDetailsColumns} dataSource={this.state.productPlanData} />
													  </div>
													  <br/>
													  <div style={{marginTop:50,marginBottom:50}}>
												    		<span style={{
												    			fontSize: 18,
															    paddingRight: 21,
															    fontWeight: 600
												    		}}>Shift 2</span>
												    	<br/>
														<Table scroll={{ x:1300 }} bordered columns={wheelDetailsColumns} dataSource={this.state.productPlanData2} />
													 </div>
												    </TabPane>
												    :null}
													{(this.state.permissions.includes('Fairing Details')) ?<TabPane tab="Fairing Details" key="4">
													<div style={{marginTop:50,marginBottom:50}}>
													<br/>
												    	
												    		<span style={{
												    			fontSize: 18,
															    paddingRight: 21,
															    fontWeight: 600
												    		}}>Shift 1</span>
												    	<br/>
													  <Table scroll={{ x:1300 }} bordered columns={frFairingColumns} dataSource={this.state.productPlanData} />
													  </div>
													  <div style={{marginTop:50,marginBottom:50}}>
													  <br/>
												    	
												    		<span style={{
												    			fontSize: 18,
															    paddingRight: 21,
															    fontWeight: 600
												    		}}>Shift 2</span>
												    	<br/>
														<Table scroll={{ x:1300 }} bordered columns={frFairingColumns} dataSource={this.state.productPlanData2} />
													  </div>
												    </TabPane>
												     :null}
												    {/* <TabPane tab="Decal Details" key="5">
													  <Table bordered columns={decalColumns} dataSource={this.state.productPlanData} />
												    </TabPane> */}
													{(this.state.permissions.includes('Painted Parts Details')) ? <TabPane tab="Painted Parts Details" key="6">
													<div style={{marginTop:50,marginBottom:50}}>
													<br/>
												    	
												    		<span style={{
												    			fontSize: 18,
															    paddingRight: 21,
															    fontWeight: 600
												    		}}>Shift 1</span>
												    	<br/>
													  <Table scroll={{ x:1300 }} bordered columns={paintedPartsColumns} dataSource={this.state.productPlanData} />
													  </div>
													  <div style={{marginTop:50,marginBottom:50}}>
													  <br/>
												    	
												    		<span style={{
												    			fontSize: 18,
															    paddingRight: 21,
															    fontWeight: 600
												    		}}>Shift 2</span>
												    	<br/>
														<Table scroll={{ x:1300 }} bordered columns={paintedPartsColumns} dataSource={this.state.productPlanData2} />
													  </div>
													</TabPane>
											  		:null}
												    </Tabs>,
								    		</div>
								    	</div>

								    </TabPane>
								    <TabPane tab={this.state.tommorrow} key="2">
									<div className="row __internal_tabs">
								    		<div className="col-lg-12">
								    			<Tabs onChange={this.callback2} type="card" style={{width:'1100px'}}>
												    <TabPane tab="Production Plan" key="1">
												    	<br/>
												    	
												    		<span style={{
												    			fontSize: 18,
															    paddingRight: 21,
															    fontWeight: 600
												    		}}>Shift 1</span>
												    		<label style={{fontSize:16,marginLeft:150}}>Line Speed: </label>
															<input type="text" className="table_input" name="shift2LineSpeed" disabled={true} value={this.state.shiftT1LineSpeed} placeholder="Line Speed" style={{ width: 130, marginLeft:10 }} onChange={(e)=>this.handleTextChange(e)}/>
															<label style={{fontSize:16,marginLeft:10}}>Plan/Hour: </label>
															<input type="text" className="table_input" name="shift2planHour" disabled={true} value={this.state.shiftT1planHour} placeholder="Plan / Hour" style={{ width: 130,marginLeft:10 }} onChange={(e)=>this.handleTextChange(e)}/>
															<button style={{ marginBottom:10,borderRadius:58, float:'right' }} onClick={()=>this.exportToExcel(this.state.productPlanDataT,this.state.productPlanDataT2,this.state.tommorrow)} className="add_btn save-btn">EXPORT TO EXCEL</button>
												    	<br/>
												      <Table bordered columns={productPlanColumnsT} dataSource={this.state.productPlanDataT} />
													  <br/>
												    	
												    		<span style={{
												    			fontSize: 18,
															    paddingRight: 21,
															    fontWeight: 600
												    		}}>Shift 2</span>
															<label style={{fontSize:16,marginLeft:150}}>Line Speed: </label>
															<input type="text" className="table_input" name="shift2LineSpeed" disabled={true} value={this.state.shiftT2LineSpeed} placeholder="Line Speed" style={{ width: 130, marginLeft:10 }} onChange={(e)=>this.handleTextChange(e)}/>
															<label style={{fontSize:16,marginLeft:10}}>Plan/Hour: </label>
															<input type="text" className="table_input" name="shift2planHour" disabled={true} value={this.state.shiftT2planHour} placeholder="Plan / Hour" style={{ width: 130,marginLeft:10 }} onChange={(e)=>this.handleTextChange(e)}/>
												    	<br/>
												      <Table bordered columns={productPlanColumnsT2} dataSource={this.state.productPlanDataT2} />
												    </TabPane>
													{(this.state.permissions.includes('Frame Details')) ? <TabPane tab="Frame Details" key="2">
													<div style={{marginTop:50,marginBottom:50}}>
													<br/>
												    	
												    		<span style={{
												    			fontSize: 18,
															    paddingRight: 21,
															    fontWeight: 600
												    		}}>Shift 1</span>
												    	<br/>
												      <Table scroll={{ x:1300 }} bordered columns={frameDetailsColumns} dataSource={this.state.productPlanDataT} />
													  </div>
													  <div style={{marginTop:50,marginBottom:50}}>
													  <br/>
												    	
												    		<span style={{
												    			fontSize: 18,
															    paddingRight: 21,
															    fontWeight: 600
												    		}}>Shift 2</span>
												    	
												    	<br/>
														<Table scroll={{ x:1300 }} bordered columns={frameDetailsColumns} dataSource={this.state.productPlanDataT2} />
														</div>
												    </TabPane>
												    :null}
													{(this.state.permissions.includes('Wheel Details')) ? <TabPane tab="Wheel Details" key="3">
													<div style={{marginTop:50,marginBottom:50}}>
													<br/>
												    	
												    		<span style={{
												    			fontSize: 18,
															    paddingRight: 21,
															    fontWeight: 600
												    		}}>Shift 1</span>
												    	
												    	<br/>
													  <Table scroll={{ x:1300 }} bordered columns={wheelDetailsColumns} dataSource={this.state.productPlanDataT} />
													  </div>
													  <div style={{marginTop:50,marginBottom:50}}>
													  <br/>
												    	
												    		<span style={{
												    			fontSize: 18,
															    paddingRight: 21,
															    fontWeight: 600
												    		}}>Shift 2</span>
												    	
												    	<br/>
														<Table scroll={{ x:1300 }} bordered columns={wheelDetailsColumns} dataSource={this.state.productPlanDataT2} />
														</div>
												    </TabPane>
												   : null}
												   {(this.state.permissions.includes('Fairing Details')) ? <TabPane tab="Fairing Details" key="4">
													<div style={{marginTop:50,marginBottom:50}}>
													<br/>
												    	
												    		<span style={{
												    			fontSize: 18,
															    paddingRight: 21,
															    fontWeight: 600
												    		}}>Shift 1</span>
												    	<br/>
													  <Table scroll={{ x:1300 }} bordered columns={frFairingColumns} dataSource={this.state.productPlanDataT} />
													  </div>
													  <div style={{marginTop:50,marginBottom:50}}>
													  <br/>
												    	
												    		<span style={{
												    			fontSize: 18,
															    paddingRight: 21,
															    fontWeight: 600
												    		}}>Shift 2</span>
												    	<br/>
														<Table scroll={{ x:1300 }} bordered columns={frFairingColumns} dataSource={this.state.productPlanDataT2} />
														</div>
												    </TabPane>
												    : null}
												    {/* <TabPane tab="Decal Details" key="5">
													  <Table bordered columns={decalColumns} dataSource={this.state.productPlanData} />
												    </TabPane> */}
													{(this.state.permissions.includes('Painted Parts Details')) ? <TabPane tab="Painted Parts Details" key="6">
													<div style={{marginTop:50,marginBottom:50}}>
													<br/>
												    	
												    		<span style={{
												    			fontSize: 18,
															    paddingRight: 21,
															    fontWeight: 600
												    		}}>Shift 1</span>
												    	<br/>
													  <Table scroll={{ x:1300 }} bordered columns={paintedPartsColumns} dataSource={this.state.productPlanDataT} />
													  </div>
													  <div style={{marginTop:50,marginBottom:50}}>
													  <br/>
												    	
												    		<span style={{
												    			fontSize: 18,
															    paddingRight: 21,
															    fontWeight: 600
												    		}}>Shift 2</span>
												    	<br/>
														<Table scroll={{ x:1300 }} bordered columns={paintedPartsColumns} dataSource={this.state.productPlanDataT2} />
														</div>
												    </TabPane>
											  		: null}
												    </Tabs>
								    		</div>
								    	</div>

								    </TabPane>
								 </Tabs>
								 <Modal
									title='Part Image'
									visible={this.state.hover}
									onCancel={this.handleMouseOut}
								>
									<Image
										src={`http://bajaj.alphaobs.com/images/${this.state.image}`}
										fallback="http://bajaj.alphaobs.com/images/no_image.jpg"
									/>
									{/* <img src="./assets/52JP0302.png" ref={img => this.img = img} alt="Part Image" onError={(error)=>this.img.src='./assets/logo.png'}/> */}
								</Modal>
							 </div>
						</div>
					</div>
			</section>
		)
	}


}

export default ProductPlan;