import React, { Component } from "react";
import { Spin,message } from 'antd';
import axios from "axios";
class Login extends Component {

	state = {
		spinner:false,
		email:"",
		password:""
	}

	redirectTo = () => {
		if(this.state.email==="" || this.state.password===""){
			message.error("Please fill all fields")
			return
		}
		this.setState({ spinner: true })
		const params = new URLSearchParams()
		params.append('vendor_username', this.state.email)
		params.append('vendor_password', this.state.password)

		axios.post(process.env.REACT_APP_BASE_URL+"/vendor/login",params,{
			headers:{
				'Content-Type':'application/x-www-form-urlencoded'
			}
		}).then((res)=>{
			console.log(res)
			if(res.data.status===1){
				localStorage.setItem('token',res.data.token)
				localStorage.setItem('permissions',res.data.permissions)
				this.setState({ spinner: false },() => window.location.assign('/ProductPlan'))
			}else{
				this.setState({ spinner: false })
				message.error(res.data.message)
			}
		})
	}

	componentDidMount(){
		let token = localStorage.getItem('token')
		if(token){
			window.location.assign('/ProductPlan')
		}
	}
	render() {
		return (
			<section className="__home_section">
				<div class="logo">
					<img src="./assets/logo.png" style={{ height:44,marginTop:15,marginLeft:42,position:"absolute" }} alt="" />
				</div>
					
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-7">

								<div class="row">
									<div class="col-lg-7 m-auto">
										<h6 className="text-center">Vendor Guidance System<br></br>Vendor Login</h6>
										<Spin size="large" spinning={this.state.spinner}>
											<div className="form">
												<input type="text" placeholder="Enter Email ID" onChange={(e)=>this.setState({email:e.target.value})}/><br/>
												<input type="password" placeholder="Enter Password" onChange={(e)=>this.setState({password:e.target.value})}/><br/>
												<center>
													<button type="button" onClick={this.redirectTo}>Login</button>
												</center>
											</div>
										</Spin>
									</div>
								</div>

							</div>

							<div class="col-lg-5 pr-0 d-none d-sm-none d-xs-none d-md-none d-lg-block d-xl-block">
								<div class="__msg_right_block text-center">
								</div>
							</div>

						</div>
					</div>

			</section>
		)
	}


}

export default Login;